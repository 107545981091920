import { h, Component } from 'preact';
const style = require('./style');

import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

interface MyProps {
  stepperButtonClass?: string;
  min: number;
  max: number;
  step?: number;
  initialValue: number;
  minIcon: typeof Icon;
  plusIcon: typeof Icon;
  reverseIcons?: boolean;
  fab?: boolean;
  showValue: boolean;
  shouldChange?(): boolean;
  onChange?(value: number): void;
  onIncrement?(value: number): void;
  onDecrement?(value: number): void;
}

interface MyState {
  value: number;
}

export default class Stepper extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      value: props.initialValue
    }
  }

  componentDidUpdate?(previousProps: MyProps, previousState: MyState, previousContext: any) {
    if (previousProps.initialValue !== this.props.initialValue) {
      this.setState({value: this.props.initialValue});
    }
  }

  onClickHandler(operation: number, isDisabled: () => boolean) {
    return () => {
      if (isDisabled()) return;
      if (!this.props.shouldChange||this.props.shouldChange()) {
        this.setState(
          ({value})=>({value: value+this.props.step*operation}),
          ()=>{
            this.props.onChange&&this.props.onChange(this.state.value)
            if (operation===1) {
              this.props.onIncrement&&this.props.onIncrement(this.state.value);
            } else if (operation===-1) {
              this.props.onDecrement&&this.props.onDecrement(this.state.value);
            }
          }
        )
      }
      

    }
  }

  render({min, max, minIcon: MinIcon, plusIcon: PlusIcon, reverseIcons=false, fab=true, showValue=false, stepperButtonClass}: MyProps, {value}: MyState) {
    const stepperButtonClassesArray = [style.stepperButton];
		if (stepperButtonClass) stepperButtonClassesArray.push(stepperButtonClass);
		const stepperButtonClasses = stepperButtonClassesArray.join(" ");
    
    let minusDisabled = () => value<=min;
    let plusDisabled = () => value>=max;

    let MinButton = fab
      ?<Fab onClick={this.onClickHandler(-1, minusDisabled)} disabled={minusDisabled()}><MinIcon /></Fab>
      :<MinIcon onClick={this.onClickHandler(-1, minusDisabled)}/>;

    let PlusButton = fab
      ?<Fab onClick={this.onClickHandler(1, plusDisabled)} disabled={plusDisabled()}><PlusIcon /></Fab>
      :<PlusIcon onClick={this.onClickHandler(1, plusDisabled)}/>;
    if (reverseIcons) {
      [MinButton, PlusButton] = [PlusButton, MinButton]
    }
    return (
      <div class={style.stepper}>
        {PlusButton}
        {showValue&&<span class={style.value}>{value}</span>}
        {MinButton}
      </div>
    );
  }
}
