import { h, Component } from 'preact';
import firebase from 'firebase/app';
import 'firebase/auth'

const style = require('./style');


interface MyProps {}
interface MyState {}

export default class Login extends Component<MyProps, MyState> {
  async componentDidMount() {
    if (typeof window !== "undefined") {
      firebase.auth().onAuthStateChanged(async user=>{
        console.log('user:',user)
        if (!user) {
          const firebaseui = await import(/* webpackChunkName: "firebaseui" */'firebaseui');
          // Initialize FirebaseUI
          let uiConfig = {
            signInSuccessUrl: '/',
            signInOptions: [
              // Leave the lines as is for the providers you want to offer your users.
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
              //firebase.auth.TwitterAuthProvider .PROVIDER_ID,
              //firebase.auth.GithubAuthProvider.PROVIDER_ID,
              firebase.auth.EmailAuthProvider.PROVIDER_ID
            ],
            // Terms of service url.
            tosUrl: ''
          };

          // Initialize the FirebaseUI Widget using Firebase.
          let ui = new firebaseui.auth.AuthUI(firebase.auth());
          // The start method will wait until the DOM is loaded.
          ui.start('#firebaseui-auth-container', uiConfig);
        }
      });
      
    }
  }
  render() {
    return (
      <div class={style.login}>
        <div id="firebaseui-auth-container"/>
      </div>
    );
  }
}
