import devtools from 'unistore/devtools'

import createStore from 'unistore';

const initialState: AppState = {
	selectedSize: '13x18',
	selectedBaseSize: '10x15',
	orderedPictures: [],
	pictures: [],
	selectedPictures: []
}
const production = false;//window.location.hostname.endsWith('fotoservicewinkel.nl')

export let store = production ? createStore(initialState) : devtools(createStore(initialState));
// export const store = createStore(initialState);
