
import { h, Component } from 'preact';
const style = require('./style');

const {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableCell
} = require('@rmwc/data-table') as any;

import '@rmwc/data-table/data-table.css';

import { calculateUnitPrice, toEuros, calculateShoppingCartTotal } from '../../helpers/util'
import { START_COST } from '../../helpers/constants';


interface MyProps {
  items: ShoppingCart.item[];
}

const ShoppingCartTable = ({items} : MyProps) => (
  <DataTable style={{maxWidth: '100%', height: '100%'}} stickyRows={1} stickyColumns={1}>
    <DataTableContent>
      <DataTableHead>
        <DataTableRow>
          <DataTableHeadCell>Product</DataTableHeadCell>
          <DataTableHeadCell alignEnd style={'padding-right: 0.25rem !important'}></DataTableHeadCell>
          <DataTableHeadCell alignEnd>Prijs</DataTableHeadCell>
          <DataTableHeadCell alignEnd>Totaal</DataTableHeadCell>
        </DataTableRow>
      </DataTableHead>
      <DataTableBody>
        {items.map(({size, amount})=>{
          const unitPrice = calculateUnitPrice(size, amount)
          return <DataTableRow>
            <DataTableCell>{size}</DataTableCell>
            <DataTableCell alignEnd style={'padding-right: 0.25rem !important'}>{amount}x</DataTableCell>
            <DataTableCell alignEnd>{toEuros(unitPrice)}</DataTableCell>
            <DataTableCell alignEnd>{toEuros(unitPrice*amount)}</DataTableCell>
          </DataTableRow>
        })}
        <DataTableRow>
          <DataTableCell>Startkosten</DataTableCell>
          <DataTableCell></DataTableCell>
          <DataTableCell></DataTableCell>
          <DataTableCell alignEnd>{toEuros(START_COST)}</DataTableCell>
        </DataTableRow>
        <DataTableRow activated>
          <DataTableCell>Totaal</DataTableCell>
          <DataTableCell>{items.reduce((a,b)=>a+b.amount, 0)}x</DataTableCell>
          <DataTableCell></DataTableCell>
          <DataTableCell alignEnd>{toEuros(calculateShoppingCartTotal(items))}</DataTableCell>
        </DataTableRow>
      </DataTableBody>
    </DataTableContent>
  </DataTable>
)

export default ShoppingCartTable