import { h, Component } from 'preact';
const style = require('./style');

import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';

import {Stepper} from '../'
import { getCurrentUser, ref, getValueAsync } from '../../helpers/firebase';

import firebase from 'firebase/app';
import 'firebase/auth'

import { handleFiles, getSharedImages } from 'helpers/uploaderutils';
import { Message } from '@material-ui/icons';

interface MyProps {
  onUploadComplete: Function;
}
interface MyState {
  awaitingShareTarget: boolean;
  uploadProgress: number[];
  snackbarMessage: string;
  snackbarOpened: boolean;
  user?: firebase.User;
}

firebase.auth().onAuthStateChanged(async user => {

  if (!user) return;
  const userRef = (await ref('users')).doc(user.uid);
  const userData = await getValueAsync(userRef);
  if (!userData || !userData.currentOrder) {
    const currentOrderRef = await (await ref('orders')).add({});
    userRef.set({currentOrder: currentOrderRef.id});
  }
})

export default class Uploader extends Component<MyProps, MyState> {
  fileInputElement: HTMLInputElement;
  fileInputLabel: HTMLLabelElement;
  uploaderElement: HTMLDivElement;
  bar: any;

  showMessage?(message: string) {
    this.setState({
      snackbarMessage: message,
      snackbarOpened: true
    });
  }

  handleClose?() {
    this.setState({
      snackbarOpened: false
    });
  }

  constructor(props: MyProps) {
    super(props);
    this.state = {
      awaitingShareTarget: new URL(location.href).searchParams.has('share-target'),
      uploadProgress: [],
      snackbarMessage: "",
      snackbarOpened: false,
      user: null
    }

    if (typeof window !== 'undefined') {
			firebase.auth().onAuthStateChanged(user => this.setState({user}));
		}

    navigator?.serviceWorker?.ready.then(async () => {
      console.log('awaitingShareTarget', this.state.awaitingShareTarget)
      
      if (!this.state.awaitingShareTarget) return;
      const files = await getSharedImages();
      console.log('files', files)
      // Remove the ?share-target from the URL
      history.replaceState('', '', '/upload');
      console.log('navigator.serviceWorker.ready, files: '+files.length);
      handleFiles(files);

      this.setState({ awaitingShareTarget: false });
    });
  }

  async componentDidMount() {
    this.fileInputElement = document.querySelector(`.${style.fileinput}`);
    this.fileInputLabel = document.querySelector(`.${style.fileinputlabel}`);
    this.uploaderElement = document.querySelector(`.${style.uploader}`);
    const htmlElement = document.children[0];

    htmlElement.addEventListener('dragover', ev => {
      ev.preventDefault();
    });

    htmlElement.addEventListener('drop', async (ev: CustomEvent & { dataTransfer?: DataTransfer}) => {
      ev.preventDefault();
      handleFiles.call(this, ev.dataTransfer.files);
    });

    this.fileInputElement.addEventListener("change", () => {
      handleFiles.call(this, this.fileInputElement.files)
    }, false);

    this.fileInputLabel.addEventListener("click", () => {
      const user = getCurrentUser();
      if (!user) {
        this.showMessage('U bent niet ingelogd');
      }
    }, false);
  }

  render(props: MyProps, {uploadProgress, snackbarMessage, snackbarOpened, user}: MyState) {
    function add(a: number, b: number): number { return a+b; }
    const progress = uploadProgress.reduce(add, 0)/uploadProgress.length||0;
    if (progress===1) {
      this.setState({uploadProgress:[]})
      const message = `${uploadProgress.length} foto${uploadProgress.length===1?' is':'s zijn'} geupload`;
      this.showMessage(message);
    }
    const isLoggedIn = Boolean(user);
    console.log({isLoggedIn, user})
    return (
      <div class={style.uploader}>
      	{/* <Stepper min={0} max={10} step={1} initialValue={2} minIcon='zoom_out' plusIcon='zoom_in' onChange={(progress)=>this.setState({uploadProgress:[progress/10]})} fab={true} showValue={false}></Stepper> */}
        <div class={style.progress} style={progress===0?'transform: scaleY(0); max-height:0':''}>
          <p>{((progress||0)*100).toFixed(1)}%</p>
          <LinearProgress value={progress*100}/>
        </div>
        <label for="fileInput" class={style.fileinputlabel} disabled={!isLoggedIn}>Upload uw foto's</label>
        <input id="fileInput" class={style.fileinput} type="file" accept="image/*" multiple disabled={!user}/>

        <Snackbar
          open={snackbarOpened}
          onClose={this.handleClose}
          message={snackbarMessage}
        />
      </div>
    );
  }
}
