
import firebase from 'firebase/app';
import { combinePictures, defaultCrop, findOrderedPicByOrderInfo, findPic, findPicByFilename, firestore } from 'helpers/util'
import { Store } from 'unistore';
import { getCurrentUser } from './firebase/auth';

// console.log('hello from actions');

export default (store: Store<AppState>): Actions => ({

  /*
    This function contains a bug

    If you select a different size and base size, then plus one an image, the right image gets +1'd
    but there also appears a new image every time you +1 in <size> (also buggy behavior when trying
    to change the value of the bugged images)
  */
  setOrderCount({orderedPictures, pictures, selectedSize}, filename, size, value) {
    if (value < 0) value = 0;
    const index = orderedPictures.findIndex(x=>x.filename===filename&&x.size===size);
    const picture = pictures.find(x=>x.filename===filename);

    let orderedPicture: orderedPicture;

    // console.log(JSON.stringify({selectedSize, filename, size, value, index, picture}, null, 2));
    // The picture has already been ordered before
    if (index>=0) {
      orderedPicture = orderedPictures[index];
      // if the count was set to zero, delete it.
      if (value === 0) {
        orderedPictures.splice(index, 1);
      } else {
        orderedPictures[index].amount = value;
      }
    } else {
      orderedPicture = {
        ...picture,
        size,
        amount: 1,
        crop: defaultCrop(size, picture.sourceWidth, picture.sourceHeight),
        border: null
      };

      orderedPictures.push(orderedPicture);
    }

    (async ()=>{
      const {getValueAsync, ref} = await import('helpers/firebase/firestore');

      const { uid } = getCurrentUser();
      const { currentOrder } = await getValueAsync((await ref('users')).doc(uid));
      ref('orders').then(ordersRef => {
        const orderInfo = {
          amount: orderedPicture.amount,
          crop: orderedPicture.crop,
          border: orderedPicture.border,
          size
        }

        ordersRef.doc(currentOrder).set({
          [filename]: value > 0 ? orderInfo : firebase.firestore.FieldValue.delete()
        }, {merge:true});
      })
    })();

    return { orderedPictures }
  },


  // incrementOrderCount({orderedPictures, selectedSize}, filename, size, value) {
  //   const index = orderedPictures.findIndex(x=>x.filename===filename&&x.size===selectedSize);
  //   // The picture has already been ordered before (and in this size)
  //   if (index>=0) {
  //     orderedPictures[index].amount = value;
  //   } else {
  //     orderedPictures.push({filename, size: selectedSize, amount: 1});
  //   }
  //   console.table(orderedPictures)
  //   return { orderedPictures }
  // },

  // decrementOrderCount({orderedPictures, selectedSize}, filename, size, value) {
  //   if (value < 0) {
  //     console.error('Amount of pictures cannot be smaller than 0')
  //   }
  //   const index = orderedPictures.findIndex(x=>x.filename===filename&&x.size===size);
  //   // The picture has already been ordered before (and in this size)
  //   if (index>=0) {
  //     orderedPictures[index].amount = value;
  //   } else {
  //     console.error('Cannot decrement picture that does not exist')
  //   }
  //   console.table(orderedPictures)
  //   return { orderedPictures }
  // },
  setSelectedSize(state, selectedSize) {
    return { selectedSize }
  },
  setSelectedBaseSize(state, selectedBaseSize) {
    return { selectedBaseSize }
  },
  addSize({pictures, orderedPictures, selectedSize, selectedPictures}) {
    console.log('orderedPictures', orderedPictures)
    selectedPictures.forEach(selectedPicture => {
      let maxIndex = -1;
      const sameFilename = orderedPictures.filter((pic, index) => {
        const hasSameFilename = pic.filename == selectedPicture.filename
        if (hasSameFilename && index > maxIndex) maxIndex = index;
        console.log(JSON.stringify({pic, selectedPicture, hasSameFilename, index, maxIndex}));
        return hasSameFilename;
      })
      if (!sameFilename.find(pic => selectedSize == pic.size)) {
        const picSameFileName = pictures.find(pic => selectedPicture.filename == pic.filename);

        const width = picSameFileName.sourceWidth;
        const height = picSameFileName.sourceHeight;
        // insert new pic after the ones with the same filename
        orderedPictures.splice(maxIndex+1, 0, {
          ...picSameFileName,
          amount: 0,
          size: selectedSize,
          crop: defaultCrop(selectedSize, width, height),
          border: null
        })
      }
    })

    return { orderedPictures }
  },
  addPictures({pictures}, newPictures) {
    // console.log('pics before', pictures)
    // console.log('new pics',newPictures)
    newPictures.forEach(newPicture => {
      const index = pictures.findIndex(findPicByFilename(newPicture.filename));
      if (index !== -1) {
        pictures[index] = newPicture;
      } else {
        pictures.push(newPicture);
      }
    })

    // console.log('pics after', pictures)

    return { pictures };
  },

  setChecked({selectedPictures}: AppState, picture: selectedPicture, checked: boolean) {
    const index = selectedPictures.findIndex(findPic(picture));
    if (index < 0) {
      if (checked) {
        selectedPictures.push({filename: picture.filename, size: picture.size});
      }
    } else {
      selectedPictures.splice(index, 1)
    }
    console.log(selectedPictures)
    return { selectedPictures }
  },

  addSizeWithCrop({ orderedPictures, pictures }, orderInfo: orderInfo) {
    const index = orderedPictures.findIndex(findOrderedPicByOrderInfo(orderInfo));
    const normalPicture = pictures.find(findPicByFilename(orderInfo.filename));
    const orderedPicture = {
      ...normalPicture,
      ...orderInfo
    };

    // console.log('normalPicture', normalPicture);
    // console.log('orderedPicture', orderedPicture);

    if (index !== -1) {
      orderedPictures[index] = orderedPicture;
    } else {
      orderedPictures.push(orderedPicture);
    }

    return { orderedPictures }
  }


  // setSourceSize({pictures}: AppState, filename: string, width: number, height: number) {
  //   const pic = pictures.find(findPicByFilename(filename));
  //   pic.sourceWidth = width;
  //   pic.sourceHeight = height;
    
  //   console.log(pic);
  //   return { pictures };
  // }

});