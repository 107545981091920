import { h, Component } from 'preact';
import firebase from 'firebase/app';
import { firestore, toEuros, calculateUnitPrice, DOM, mapStateToShoppingCart } from 'helpers/util';
import 'firebase/auth';
const style = require('./style');

import FunctionButton from 'components/functionbutton';
import actions from 'helpers/actions';
import { connect } from 'unistore/preact';

interface MyProps {
}

interface MyState {
  orders:any[]
}

export interface InjectedProps {
  items?: ShoppingCart.item[];
}

@connect<MyProps, MyState, AppState, InjectedProps & ActionProps>(mapStateToShoppingCart, actions)
export default class ShoppingCart extends Component<MyProps & InjectedProps & ActionProps, MyState> {
  constructor(props) {
    super(props);
    this.state = {
      orders:[]
    }
  }

  componentDidMount?() {
    console.log('shoppingcart component did mount')
    Promise.resolve()
      .then(() => firestore())
      .then(()=>{
        const db = firebase.firestore();
        let unsubscribe: ()=>void;
        firebase.auth().onAuthStateChanged(user => {
          if (unsubscribe) unsubscribe();
          console.log(user);
          if (user) {
            unsubscribe = db.collection('ordermetadata').where('ownerId','==',user.uid)
              .onSnapshot(snaps => {
                const orders = snaps.docs.map(doc=>({id:doc.id,...doc.data()}));
                this.setState({orders});
                console.table(orders)
              })
          } else {
            this.setState({orders:[]});
          }
        });
      });
    
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.items 
  // }

  render({ items }, { orderId, orders }): preact.ComponentChild {
    return (
      <div class={style.shoppingCart}>
        <ul>
          {Object.keys(items).map(item=>{
            const amount = items[item];
            const unitPrice = calculateUnitPrice(item, amount);
            //console.log(amount, unitPrice)
            return <li>
              {amount}x {item} @ {toEuros(unitPrice)} = {toEuros(amount*unitPrice)}
            </li>
          })}
        </ul>
        <FunctionButton
          name="create"
          options={{amount:Math.ceil(Math.random()*1e3)/1e2}}
        >Create order</FunctionButton>
        
        <table>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Payment Status</th>
            <th></th>
          </tr>
          {orders&&orders.map(order=>
            <tr>
              <td>{order.id}</td>
              <td>{order.amount}</td>
              <td>{order.payment&&order.payment.status}</td>
              <td><FunctionButton
                disabled={order.payment&&order.payment.status.includes('paid')}
                name="pay"
                options={{orderId:order.id}}
                callback={result=>window.location = result.data.paymentUrl}
              >Pay</FunctionButton></td>
            </tr>
          )}
        </table>
      </div>
    );
  }
}
