import { h, Component } from 'preact';
import { Router, RouterOnChangeArgs } from 'preact-router';

import createStore from 'unistore'
import { Provider } from 'unistore/full/preact'
//import devtools from 'unistore/devtools'

import Header from './header';
import Home from 'routes/home';
import Upload from 'routes/upload';
import Checkout from 'routes/checkout';

// import Home from 'async!../routes/home';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';


import { store } from 'helpers/store';

interface MyProps {}
interface MyState {
	currentUrl: string
}

export default class App extends Component<MyProps, MyState> {

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	currentUrl: string;
	handleRoute = (e: RouterOnChangeArgs) => {
		this.setState({
			currentUrl: e.url
		});
	};

	componentDidMount() {
		console.log('App component did mount')
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<div id="app">
						<Header selectedRoute={this.state.currentUrl}/>
						<Router onChange={this.handleRoute}>
							<Home path="/"/>
							<Upload path="/upload" store={store}/>
							<Checkout path="/checkout"/>
							{/*<Login path="/login" />*/}

						</Router>
					</div>
				</Provider>
			</ThemeProvider>
		);
	}
}