import prices from './prices'
import { START_COST } from './constants'
import firebase from 'firebase/app'
import { InjectedProps as PictureListInjectedProps } from '../components/picturelist';

// console.log('hello from util');

// € 1.234,56 (has a space between number and euro sign)
// export const toEuros = (amount:number=0) => amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
// €1.234,56 ( does not have a space between number and euro sign)
export const toEuros = (amount:number=0) => '€'+amount.toLocaleString('nl-NL', {minimumFractionDigits: 2, maximumFractionDigits: 2});

// {x:[0,1], y:[5,6]} goes in
// [{x:0,y:5}, {x:1, y:6}] comes out.
export function combineArrays(arrays) {
	const arrayNames = Object.keys(arrays);
	const newArray = arrays[arrayNames[0]].map((_,i)=>{
		const newObj = {}
		arrayNames.forEach(arrayName=>{
			newObj[arrayName]=arrays[arrayName][i]
		});
		return newObj;
	});
	return newArray;
}

export function calculateUnitPrice(size: string, amount: number): number {
	const unitPrice = new Map(<any>prices).get(size)
	if (typeof unitPrice==="number") {
		return unitPrice
	}
	// unitPrice is not a number, so it's a map of numbers that we need to pick the right one from.
	for (const [min,max,cost] of <number[][]>unitPrice) {
		/**
		If the amount of pictures the user ordered is higher or
		equal to the minimum amount of pictures for the price 
		and the amount is lower than the minimum amount of pictures for the next lowest price
		then it's the right price and we should take it
		*/
		
		if (min<=amount&&max>=amount) return cost;
	}
}

export function calculateShoppingCartTotal(items: ShoppingCart.item[]): number {
	return items.reduce((acc, {size, amount})=>{
		return acc+calculateUnitPrice(size, amount)*amount;
	}, START_COST);
}

export function calculateTotalItems(items: ShoppingCart.item[]): number {
	return items.reduce((acc, {size, amount})=>{
		return acc+amount;
	}, 0);
}

export function mapStateToShoppingCart({orderedPictures} : AppState, props: any) {
	const amounts = orderedPictures.reduce((acc, picture) => {
		if (picture.amount == 0) return acc;
		if (typeof acc[picture.size] === "undefined") {
			acc[picture.size] = 0;
		}
		acc[picture.size] += picture.amount;
		return acc;
	}, {});
	return {
		...props,
		items: Object.keys(amounts).map(size => ({size, amount: amounts[size]}))
	}
}

export function findPic (picture1: selectedPicture) {
	return (picture2: selectedPicture) => 
			picture1.filename === picture2.filename&&
			picture1.size === picture2.size;
}

export function findPicByFilename(filename: string) {
	return (pic: selectedPicture) => pic.filename === filename;
}

export function findOrderedPicByOrderInfo(orderInfo: orderInfo) {
	return (pic: orderedPicture) => 
			pic.filename === orderInfo.filename&&
			pic.size === orderInfo.size;
}

//{mappedOrderedPictures?: mappedOrderedPicture[]}
export function mapStateToPictureList(state: AppState): PictureListInjectedProps {
	// console.log("mapStateToPictureList, state: ", state);
	const pictures = combinePictures(state).map(picture => ({
		...picture,
		selected: state.selectedPictures.findIndex(findPic(picture))>=0
	}));

	// console.log("mapStateToPictureList, pictures: ", pictures);

	// TODO: Also sort by the second dimension
	const sortedPictures = pictures.sort((a,b) => {
		if (a.filename != b.filename) return 0;

		return parseInt(a.size.split("x")[0]) -
					 parseInt(b.size.split("x")[0])
	});

	console.log("mapStateToPictureList, sortedPictures: ", sortedPictures);

	const props = {
		mappedOrderedPictures: sortedPictures
	}
	// console.log("mapStateToPictureList, props: ", props);
	return props;
}

export function combinePictures({orderedPictures, pictures, selectedBaseSize}:AppState) {
	console.warn("combinepictures, pictures: ", pictures.length, pictures[0], pictures[1]);
	let displayPictures = [...orderedPictures];
	console.warn('orderedPictures', orderedPictures[0], orderedPictures[1]);
	pictures.forEach(picture1=>{
		const index = displayPictures.findIndex(picture2=>
			picture1.filename === picture2.filename &&
			picture2.size === selectedBaseSize
		);
		if (
			index === -1
		) {
			// console.log("combinepictures, pushing: ", picture1);
			// console.log(picture1)
			displayPictures.push({
				...picture1,
				size: selectedBaseSize,
				amount: 0,
				crop: defaultCrop(selectedBaseSize, picture1.sourceWidth, picture1.sourceHeight),
				border: null
			});
		} else {
			// console.log("combinepictures, found at index: ", index);
		}
		
	})

	// TODO: make it not modify a.size and b.size
	// I think this is done?
	displayPictures.sort((a,b) => {
		// sort by date first
		if (a.lastModified < b.lastModified)
			return -1;
		if (a.lastModified > b.lastModified)
			return 1;
		// then by size
		let asize = a.size;
		let bsize = b.size;
		if (typeof asize === "undefined") {
			asize = "0";
		}
		if (typeof b.size === "undefined") {
			bsize = "0";
		}
		a.size = a.size.padStart(5, "0");
		b.size = b.size.padStart(5, "0");
		// compare the strings
		if (asize < bsize) {
			return -1;
		}
		if (asize > bsize) {
			return 1;
		}
		return 0;
	})
	// console.log("combinepictures, displayPictures ", displayPictures);
	return displayPictures;
}

export const sizes = prices.map(size=>size[0]).filter(size=>size!=="start")

export async function firestore() {
	await import(/* webpackChunkName: "firestore" */'firebase/firestore');
}
// TODO: Implement https://stackoverflow.com/questions/8100576/how-to-check-if-dom-is-ready-without-a-framework
export function DOM() {
	return new Promise(resolve=>document.addEventListener("DOMContentLoaded", resolve));
}

// TODO:
// interface mapInput<T> {
// 	[key: string]:T
// }

// interface mapOutput<U> {
// 	[key: string]:U
// }

// export function mapObject<T,U>(obj: mapInput<T>, callbackfn: (value: T, obj: mapInput<T>) => U): mapOutput<U> {
// 	let returnValue: mapOutput<U> = {};
// 	Object.keys(obj).forEach(key=>returnValue[key] = callbackfn(obj[key], obj));
// 	return returnValue;
// }

// Code above is a replacement for the code below to make it more typed but it currently breaks some things so it's not done :)

export function mapObject(object: Object, f: Function) {
	let returnValue = {};
	Object.keys(object).forEach(key=>returnValue[key] = f(object[key]));
	return returnValue;
}

export function emptyCrop(width: number, height: number): cropInfo {
	return {
		left: 0,
		top: 0,
		width,
		height
	}
}

export function defaultCrop(size: string, originalWidth: number, originalHeight: number,
	landscape: boolean = originalWidth >= originalHeight): cropInfo {
	console.log('defaultCrop called', {size, originalWidth, originalHeight, landscape});

	const [printHeight, printWidth] = size.split('x').map(x=>parseInt(x));
	let aspectRatio =  printHeight / printWidth;
	// Turn it into landscape
	if (aspectRatio > 1) aspectRatio = 1 / aspectRatio;
	// Turn it into portrait if desired
	if (!landscape) aspectRatio = 1 / aspectRatio;

	let width = originalWidth;
	let height = originalHeight;
	let left = 0;
	let top = 0;
	const imgAspectRatio = originalHeight / originalWidth;
	console.log(JSON.stringify({aspectRatio, imgAspectRatio}));

	// photo is wider than or the same size as the canvas
	if (imgAspectRatio <= aspectRatio) {
		width = originalHeight / aspectRatio;
		left = (originalWidth - width) / 2;
	} else {
		height = originalWidth * aspectRatio;
		top = (originalHeight - height) / 2;
	}

	console.log('default: ', {
		left,
		top,
		width,
		height
	})

	return {
		left,
		top,
		width,
		height
	}
}

export function emptyBorder(): borderInfo {
	return {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	}
}