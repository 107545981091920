import { h, Component } from 'preact';
const style = require('./style');

interface MyProps {}
interface MyState {}

export default class NotFound extends Component<MyProps, MyState> {
  render() {
    return (
      <div class={style.pageError}>
        <h1>Sorry, die pagina konden wij niet vinden</h1>
      </div>
    );
  }
}
