import { h, Component } from 'preact';
import { route } from 'preact-router';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingBasketIcon from '@material-ui/icons/Shoppingbasket';


import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {HeaderShoppingCart} from '../';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

const style = require('./style.css');

interface MyProps {
	selectedRoute: string;
}
interface MyState {
	darkThemeEnabled: boolean;
	drawerOpened: boolean;
	dialogOpened: boolean;
}

interface Link {
	url: string;
	text: string;
	Icon: typeof SvgIcon;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default class Header extends Component<MyProps, MyState> {
	drawer: any;
	dialog: any;

	constructor(props) {
    super(props);
		this.state = {
			darkThemeEnabled: false,
			drawerOpened: false,
			dialogOpened: false
		};
	}

	toggleDrawer = (open: boolean) => (event: any) => {
		if (event.type === 'keydown' && 
		 ((event as KeyboardEvent).key === 'Tab' ||
			(event as KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		this.setState(()=>({drawerOpened: open}))
	}

	openDrawer = () => {
		this.setState({drawerOpened: true})
	}

	closeDrawer = () => {
		this.setState({drawerOpened: false})
	}

	openDialog = () => {
		this.setState({dialogOpened: true})
	}

	closeDialog = () => {
		this.setState({dialogOpened: false})
	}

	linkTo = (path: string) => () => {
		route(path);
		this.closeDrawer();
	};

	toggleDarkTheme = () => {
		this.setState((prevState)=>({darkThemeEnabled: !prevState.darkThemeEnabled}),() => {
			if (this.state.darkThemeEnabled) {
				document.body.classList.add('mdc-theme--dark');
			} else {
				document.body.classList.remove('mdc-theme--dark');
			}
			}
		);
	}

	render({selectedRoute}: MyProps, {drawerOpened, dialogOpened, darkThemeEnabled}: MyState) {
		const classes = useStyles();

		const links: Link[] = [
			{url:'/', text: "Home", Icon: HomeIcon},
			{url:'/upload', text: "Upload", Icon: AccountCircleIcon},
			{url:'/checkout', text: "Checkout", Icon: ShoppingBasketIcon}
		];
		
		return (
			<div class={style.header}>
				<AppBar className="appbar">
					<Toolbar>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.toggleDrawer(true)}>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>FSW</Typography>


						<HeaderShoppingCart selectedRoute={selectedRoute}/>

						<IconButton onClick={this.openDialog}>
							<SettingsIcon />
						</IconButton>
					</Toolbar>
				</AppBar>

				<Drawer open={drawerOpened} onClose={this.closeDrawer}>
					<List>
						{links.map(({url, text, Icon}, index) => (
							<ListItem button key={text} onClick={this.linkTo(url)}>
								<ListItemIcon><Icon /></ListItemIcon>
								<ListItemText primary={text} />
							</ListItem>
						))}
					</List>
				</Drawer>

				<Dialog open={dialogOpened} >
					<DialogTitle>Settings</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Enable dark theme <Switch checked={darkThemeEnabled} onClick={this.toggleDarkTheme} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeDialog} color="primary">Close</Button>
        	</DialogActions>
				</Dialog>
			</div>
		);
	}
}