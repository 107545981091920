import firebase from 'firebase/app'
function initializeFirebase() {
	if (typeof window !== "undefined") {
		// Initialize Firebase
		const productionOverride = false;
		const productionUrls = [
			"fsworder-3bfd0.firebaseapp.com"
		];
		const production = window.location.hostname in productionUrls || productionOverride;

		const productionConfig = {
			apiKey: "AIzaSyAPf6RV7OgkAP-REIRERfsdJOcwV6MBxgs",
			authDomain: "fsworder-3bfd0.firebaseapp.com",
			databaseURL: "https://fsworder-3bfd0.firebaseio.com",
			projectId: "fsworder-3bfd0",
			storageBucket: "fsworder-3bfd0.appspot.com",
			messagingSenderId: "914637510274"
		};

		const stagingConfig = {
			apiKey: "AIzaSyB9AA8HqbfReqOmglza-eQufNmwXTpQj6k",
			authDomain: "fsworder-staging.firebaseapp.com",
			databaseURL: "https://fsworder-staging.firebaseio.com",
			projectId: "fsworder-staging",
			storageBucket: "fsworder-staging.appspot.com",
			messagingSenderId: "639939442247"
		};

		firebase.initializeApp(production?productionConfig:stagingConfig);
	}
}

initializeFirebase();