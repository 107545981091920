import { h, Component } from 'preact';
import Button from '@material-ui/core/Button';

import { callCloudFunction } from 'helpers/firebase/functions';

import firebase from 'firebase/app';

const style = require('./style');

interface MyProps {
  disabled?: boolean;
  name: string;
  options?: object;
  callback?(result:firebase.functions.HttpsCallableResult): void;
}

interface MyState {}

export default class FunctionButton extends Component<MyProps, MyState> {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  async onClick() {
    try {
      const result = await callCloudFunction(this.props.name, this.props.options);
      console.log(result)
      this.props.callback&&this.props.callback(result)
    } catch (e) {
      console.error(e)
    }
  }

  render(props) {
    return (
      <Button variant="contained" color="secondary" disabled={this.props.disabled} onClick={this.onClick}>{props.children}</Button>
    );
  }
}
