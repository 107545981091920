import { h, Component } from 'preact';
import { useState } from 'preact/hooks';
const style = require('./style');

/* Import Components */
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'

import { toEuros, calculateTotalItems, calculateShoppingCartTotal, mapStateToShoppingCart } from '../../helpers/util'

import { connect } from 'unistore/preact'
import actions from '../../helpers/actions'

import { ShoppingCartTable } from '../'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

interface MyProps {
  items: ShoppingCart.item[];
  selectedRoute?: string;
}

function shouldShowTotal(selectedRoute: string, totalItems: number) {
  // console.log(selectedRoute)
  if (selectedRoute==="/"||totalItems===0) {
    return false;
  } else {
    return true;
  }
}

const HeaderShoppingCart = ({ items, selectedRoute }: MyProps) => {
  // console.log('headershoppingcart loaded');
  const [open, setOpen] = useState(false);

  const totalItems = calculateTotalItems(items);
  const total = calculateShoppingCartTotal(items);
  return (
    <div class={style.headershoppingcart}>
      {shouldShowTotal(selectedRoute, totalItems)&&<span onClick={()=>setOpen(true)}>Totaal: {totalItems} item{totalItems!==1&&'s'} {toEuros(total)}</span>}
      <Dialog open={open} >
					<DialogTitle>Uw Winkelwagen</DialogTitle>
					<DialogContent>
            <ShoppingCartTable items={items}/>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>setOpen(false)} color="primary">Sluiten</Button>
        	</DialogActions>
				</Dialog>
    </div>
  );
}

export default connect(mapStateToShoppingCart, actions)(HeaderShoppingCart)