import firebase from 'firebase/app';
import 'firebase/storage';

import { ref, getValueAsync } from './firestore'
import { getCurrentUser } from './auth';

import { store } from 'helpers/store';
import { mapObject } from 'helpers/util';
import actionsFunction from 'helpers/actions'
import { Action } from 'unistore';

const unboundActions = actionsFunction(store);
const actions: ActionProps = mapObject(unboundActions, (action: Action<AppState>)=>store.action(action));

export const storage = firebase.storage()
export const storageRef = storage.ref();
export const storageImagesRef = storageRef.child('images');

// turn all dots into underscores before continueing
function dedupenames(images: File[], names: object) {
  const newNames = images.map((image, index)=>image.name);

  newNames.forEach((name, index)=>{
    while (names[name]) {
      const [fileName, extension] = name.split('.');
      let dupenumber = parseInt(fileName.split('_').pop());
      if (!isNaN(dupenumber)) {
        dupenumber++
        const splitFileName = fileName.split('_')
        splitFileName.pop()
        name = splitFileName.join('_')+'_'+dupenumber+'.'+extension;
      } else {
        name = fileName+'_1.'+extension;
      }
    }
    names[name] = true;
    const file = images[index];
    if (file.name!==name) {
      images[index] = new File([file], name, { type: file.type });
    }
  });
}

export async function uploadImages(images: File[], sizes: originalSize[], onUploadComplete: Function) {
  const uid = getCurrentUser().uid;
  if (!uid) throw 'no user';
  let names = await getValueAsync((await ref('filenames')).doc(uid));
  if (typeof names !== 'object') {
    names = {};
  }
  dedupenames(images, names);
  console.log({names})
  const userImages = storageImagesRef.child(uid);
  const uploadTasks = images.map(image => {
    return userImages.child(image.name).put(image);
  });
  const uploadProgress = Array(uploadTasks.length);
  uploadTasks.forEach((uploadTask, index)=>{
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
      const progress = uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes;
      uploadProgress[index] = progress;
      this.setState({uploadProgress});
    }, error => {
      console.error(error);
    }, async () => {
      const {lastModified, name} = images[index];
      const {width: sourceWidth, height: sourceHeight} = sizes[index];
      (await ref('filenames')).doc(uid).set({[name]:{
        lastModified,
        sourceWidth,
        sourceHeight
      }},{merge:true});
      actions.addSize()
    })
  });

  onUploadComplete();
}

// store.subscribe((state, action) => {
//   console.log(state, action)
// })