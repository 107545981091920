import actionsFunction from '../../helpers/actions';

import firebase from 'firebase/app';
import { firestore, combineArrays } from 'helpers/util';

import { store } from 'helpers/store';
import { mapObject } from 'helpers/util';

// console.log('hello from firestore');
// console.trace();

const unboundActions = actionsFunction(store);
const actions: ActionProps = mapObject(unboundActions, action=>store.action(action));

async function root() {
  await firestore();
  return firebase.firestore();
}

export async function ref(collection: string) {
  return (await root()).collection(collection);
}

export const getValueAsync = (doc: firebase.firestore.DocumentReference) => doc.get().then(x=>x.data());

let unsubFileNames: Function;
let unsubOrders: Function;
export async function addListeners() {
  const {
    storageImagesRef
  } = await import('../firebase');
  return firebase.auth().onAuthStateChanged(async user => {
    if (unsubFileNames) unsubFileNames();
    if (unsubOrders) unsubOrders();
    if (user) {
      const userId = user.uid
      unsubFileNames = (await ref('filenames')).doc(userId).onSnapshot(async snap =>{
        const filenames = snap.data();
        const filenamesList = Object.keys(filenames||{})
          .filter(filename=>filenames[filename]);
        console.log({filenames, filenamesList})
        actions.addPictures(filenamesList.map(filename=>{
            let picture = filenames[filename];
            return {
              filename,
              lastModified: picture.lastModified,
              url: 'assets/spinner.svg' // WARNING REMOVE assets/ FOR PROD
            }
          })
        );
        const urls = await Promise.all(
          filenamesList.map(filename=>
            storageImagesRef.child(userId).child(filename).getDownloadURL()
          ));
        // make an array of objects with the fields url and name from the arrays provided
        const pictures: picture[] = combineArrays({url: urls, filename: filenamesList})
          .map((picture: picture)=>{
            return {
            ...picture,
            ...filenames[picture.filename]
            }
          });
            //{...picture, ...filenames[picture.name], name: ''}));
        console.log('pictures from storage', pictures);
        actions.addPictures(pictures);

        const { currentOrder } = await getValueAsync((await ref('users')).doc(userId));
        unsubOrders = (await ref('orders')).doc(currentOrder).onSnapshot(async snap =>{
          const filenames = snap.data();
          // console.log('filenames: ', filenames)
          const filenamesList = Object.keys(filenames||{})
            .filter(filename=>filenames[filename]);
          for (const filename of filenamesList) {

            const newDisplayPic: orderInfo = {
              ...filenames[filename],
              filename
            };

            console.log('new display pic', newDisplayPic);

            actions.addSizeWithCrop(newDisplayPic);
          }
        });
      })
      
    }
  });
}