import { h, Component } from 'preact';
const style = require('./style');

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Checkbox from '@material-ui/core/Checkbox';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import {Stepper} from '../'

import actions from '../../helpers/actions';
import { Store } from 'unistore';
import { connect } from 'unistore/preact';
import { defaultCrop, mapStateToPictureList } from 'helpers/util';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { itemWidth } from 'helpers/uploadUtils';

interface MyProps {
  store?: Store<AppState>;
  selectedSize?: string;
  picsPerRow?: number;
  onDelete?: (filename: string)=>void;
}

export interface InjectedProps {
  mappedOrderedPictures?: mappedOrderedPicture[];
}


interface MyState {}

@connect<MyProps, MyState, AppState, InjectedProps & ActionProps>(mapStateToPictureList, actions)
export default class PictureList extends Component<MyProps & InjectedProps & ActionProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    // this.onIncrement = this.onIncrement.bind(this);
    // this.onDecrement = this.onDecrement.bind(this);
  }

  // onIncrement(filename, size) {
  //   return (value) => {
  //     this.props.incrementOrderCount(filename, size, value);
  //   }
  // }

  // onDecrement(filename, size) {
  //   return (value) => {
  //     this.props.decrementOrderCount(filename, size, value);
  //   }
  // }

  onChange?(filename: string, size: string) {
    return (value: number) => {
      this.props.setOrderCount(filename, size, value);
    }
  }
  
  onClickCheckbox?(picture, selectedSize) {
    if (!picture.size) {
      picture.size = selectedSize;
    }
    return (event) => {
      this.props.setChecked(picture, event.target.checked)
    }
    
  }
  
  // there's a bug in here, 
  componentDidUpdate?(previousProps: MyProps, previousState: AppState, previousContext: any) {
    // console.log('component did update')
    const { store } = this.props;
    // console.log('=======================================================');
    const state = store.getState();
    const { mappedOrderedPictures: pictures } = mapStateToPictureList(state);
    // console.log('=======================================================');
    // console.log('didmount l66')
    // console.log('cdm pics: '+pictures.length)
    // console.log({store, state: store.getState(), pictures})
    const fillFrame = false;

    const res=pictures.map((pic, index:number)=>{

      const [printHeight, printWidth] = pic.size.split('x').map(x=>parseInt(x));
      let aspectRatio = printHeight/printWidth;
      if (aspectRatio > 1) aspectRatio = 1 / aspectRatio;

      const container: HTMLDivElement = document.querySelector(`div.picturelist_container_${index}`);
      const img = container.querySelector('img');
      const canvas = container.querySelector('canvas');
      const size = container.clientWidth;

      const ctx = canvas.getContext('2d');
      
      const renderImage = () => {
        const imgWidth = img.clientWidth;
        const imgHeight = img.clientHeight;
        const originalWidth = img.naturalWidth;
        const originalHeight = img.naturalHeight;
        const imgAspectRatio = originalHeight/originalWidth;

        // console.log('img', img);
        // console.log('imgWidth', imgWidth);
        // console.log('imgHeight', imgHeight);
        // console.log('originalWidth', originalWidth);
        // console.log('originalHeight', originalHeight);
        // console.log('imgAspectRatio', imgAspectRatio);

        const landscape = imgAspectRatio <= 1;

        // horizontal or square picture
        if (landscape) {
          canvas.width = size;
          canvas.height = size*aspectRatio;
        }
        // vertical picture
        else {
          canvas.width = size*aspectRatio;
          canvas.height = size;
        }

        const {
          left: sx,
          top: sy,
          width: sWidth,
          height: sHeight
        } = pic.crop;

        // // photo is wider than or the same size as the canvas
        // if (imgAspectRatio >= aspectRatio) {
        //   sWidth = originalHeight / aspectRatio;
        //   sHeight = originalHeight;
        //   sx = (originalWidth - sWidth) / 2;
        // } else {
        //   sWidth = originalWidth;
        //   sHeight = originalWidth * aspectRatio;
        //   sy = (originalHeight - sHeight) / 2;
        // }
        
        const dWidth = canvas.width;
        const dHeight= canvas.height;
        // fill all pixels with white
        ctx.fillStyle = "#FFFFFF"; 
        ctx.fillRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, dWidth, dHeight);
        img.height=0;
      }

      const isLoaded = img.complete && img.naturalHeight !== 0;

      if (isLoaded) {
        renderImage();
      } else {
        img.onload = renderImage;
      }

      return container;
    });

    // console.log(res);
  }
  
  render(props: MyProps & InjectedProps & ActionProps): preact.ComponentChild {
    const {mappedOrderedPictures:pictures=[], picsPerRow, selectedSize} = props;

    // console.log('picturelist, mapped', pictures);

    const gap = 4; // 4px
    const controlsSize = 48;
    const borderCompensation = 0;
    const rowHeight = itemWidth(picsPerRow, gap)+controlsSize-borderCompensation;
    return (
      <ImageList className={style.picturelist} cols={picsPerRow} gap={gap} rowHeight={rowHeight}>
        {pictures.map((pic, index)=> {
          // console.log('pic source width: ', pic.sourceWidth);
          const listItemClasses = [style.picturelist_listitem];
          if (pic.selected) {
            listItemClasses.push(style.selected);
          }
          return <ImageListItem className={listItemClasses.join(' ')} key={pic.filename+" "+pic.size}>
            <div class={`${style.picturelist_aspectcontainer_image} picturelist_container_${index}`}>
              <img class={style.picturelist_image} src={pic.url}></img>
              <canvas width="0" height="0" ></canvas>
            </div>
            <ImageListItemBar className={style.picturelist_controls} title={
              <div class={style.stepper_container}>
                {typeof pic.size !== "undefined" && <span class={style.picturelist_size}>{pic.size}</span>}

                <div class={style.stepper}>
                <Stepper
                  min={0}
                  max={1e3}
                  step={1}
                  initialValue={pic.amount}
                  minIcon={RemoveCircleOutlineIcon}
                  plusIcon={AddCircleOutlineIcon}
                  reverseIcons={true}
                  fab={false}
                  showValue={true}
                  onChange={this.onChange(pic.filename, pic.size)}
                  
                  // shouldChange={()=>0}
                  // onIncrement={this.onIncrement(pic.filename, pic.size)}
                  // onDecrement={this.onDecrement(pic.filename, pic.size)}
                  
                  ></Stepper>
                </div>
                <Checkbox checked={pic.selected} onClick={this.onClickCheckbox(pic, selectedSize)}></Checkbox>
              </div>
              
            }/>
          </ImageListItem>
        })}
        
      </ImageList>
    )
  }
}