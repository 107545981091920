import { START_COST } from './constants';

const prices: [string, number | number[][]][] = [
  ["9x13", [
    [1,10, 0.45],
    [11,50, 0.39],
    [51,199, 0.35],
    [200, 1000000, 0.29]
  ]],
  ["10x15", [
    [1,10, 0.45],
    [11,50, 0.39],
    [51,199, 0.35],
    [200, 1000000, 0.29]
  ]],
  ["13x18", [
    [1,10, 0.69],
    [11,50, 0.59],
    [51,199, 0.49],
    [200, 1000000, 0.39]
  ]],
  ["15x20", 0.89],
  ["20x30", 2.99],
  ["30x40", 6.95],
  ["30x45", 6.95],
  ["start", START_COST]
]

export default prices;