import { h, Component, JSX } from 'preact';

import Fab from '@material-ui/core/Fab';

import Icon from '@material-ui/core/Icon';

import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
const style = require('./style');

interface MyProps {
  src: string;
  options: Cropper.Options
}
interface MyState {
  aspectRatio:number;
  fullPhoto: boolean;
  // initialDimensions?: any;
}

export default class ImageEditor extends Component<MyProps, MyState> {
  cropper: Cropper;
  constructor(props) {
    super(props);
    this.state = {
      aspectRatio:props.options.aspectRatio,
      fullPhoto: false
    }
    this.rotateCrop = this.rotateCrop.bind(this);
    this.reset = this.reset.bind(this);
    this.onResize = this.onResize.bind(this);
    let id = 0;
    window.addEventListener('resize', ()=>{
      clearTimeout(id)
      id = setTimeout(this.onResize,100) as any;
    })
  }

  componentDidMount() {
    const image = document.querySelector("#cropperImg") as HTMLImageElement;
    //console.log('component loaded, image: ', image)
    this.cropper = new Cropper(image, {
      crop({detail}) { console.log(detail) },
      ready() {
        let {left, top, width, height} = this.cropper.getCanvasData();
        if (height>width) height = width
        this.cropper.setCanvasData({left, top, width, height});
        console.log({left, top, width, height})
      },
      ...this.props.options
    });
    image.addEventListener("load", () => {
      console.log('image loaded, setting initial crop')
      // this.setState({initialDimensions:{width:image.naturalWidth,height:image.naturalHeight}})
      // this.setInitialCrop(image.naturalWidth, image.naturalHeight, this.state.crop.aspect)
    })
  }

  // setInitialCrop(width, height, aspect) {
  //   if (aspect < width/height) { // max size crop leaves out sides
  //     this.setState({
  //       crop: {
  //         x: (width-height*aspect)/2/width*100,
  //         height: 100,
  //         aspect
  //       }
  //     });
  //   } else { // max size crop leaves out top and bottom
  //     this.setState({
  //       crop: {
  //         y: (height-width/aspect)/2/height*100,
  //         width: 100,
  //         aspect
  //       }
  //     });
  //   }
  // }

  rotateCrop() {
    // const {width, height} = this.state.initialDimensions;
    // const {aspect} = this.state.crop
    // this.setInitialCrop(width, height, 1/aspect);
    this.setState(
      ({aspectRatio})=>({aspectRatio: 1/aspectRatio}),
      ()=>{
        this.cropper.setAspectRatio(this.state.aspectRatio)
        console.log(this.state)
      }
    );
    
    
  }

  reset() {
    console.log('resetting')
    this.cropper.reset()
  }

  onResize() {
    // const buttonMargin = 5;
    // const buttonHeight = 56+2*buttonMargin;
    // var width = window.innerWidth
    // || document.documentElement.clientWidth
    // || document.body.clientWidth;

    // var height = window.innerHeight
    // || document.documentElement.clientHeight
    // || document.body.clientHeight;



    this.reset()
  }

  // toggleWidthLimited() {
  //   const imgWrapper = document.querySelector(`.${style.imgWrapper}`);
  //   imgWrapper.classList.toggle(style.widthLimited)
  // }

  // getMaxHeight() {
  //   var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  //   const {aspectRatio} = this.state
  //   return (width-40)/aspectRatio
  // }
  // Keep the crop if parent re-renders
  // onCropComplete(crop) {
  //   this.setState({ crop });
  // }

  render({src}, {fullPhoto, aspectRatio}) {
    return (
      <div class={style.imageEditor}>
        <div class={style.buttons}>
          <Fab
            onClick={this.rotateCrop}
          ><Icon>crop_rotate</Icon></Fab>
          <Fab
            onClick={this.reset}
          ><Icon>restore</Icon></Fab>
          {/* <Fab
            class={style.materialButton}
            onClick={this.toggleWidthLimited}
            ripple={true}
          ><Icon>restore</Icon></Fab> */}
        </div>
        <div class={style.imgWrapper} style={{'--aspectRatio':2} as JSX.CSSProperties}>
          <img id="cropperImg" src={src}></img>
        </div>
      </div>
    );
  }
}
