
const MIN_PIC_WIDTH = 250;
function uploadWindowWidth() {
  return window.innerWidth-40;
}

export function maxPicsPerRow() {
  const picsPerRow = Math.floor(uploadWindowWidth()/MIN_PIC_WIDTH)||1;
  // console.log('max pics per row:', picsPerRow);
  // console.log(new Error().stack)
	return picsPerRow;
}

export function itemWidth(columns: number, gap: number) {
  const n_gaps = columns-1;
  const total_gap = n_gaps * gap;

	return (uploadWindowWidth()-total_gap)/columns;
}