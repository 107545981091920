import { getCurrentUser, ref, getValueAsync } from './firebase';

const allowedTypes = ['image/jpeg'];

export async function handleFiles(files: FileList|File[]) {
  const {uploadImages} = await import('./firebase/storage');
  console.log('received files:', files);
  console.log(`handleFiles received ${files.length} files`);
  const allFiles = Array.from(files);
  const images = allFiles.filter(file => allowedTypes.includes(file.type));
  const deniedFiles = allFiles.length-images.length;
  if (deniedFiles===1) {
    alert(`1 van de ${files.length} fotos is niet geupload omdat dit geen jpeg bestand is`);
    return;
  } else if (deniedFiles>1) {
    alert(`${deniedFiles} van de ${files.length} fotos zijn niet geupload omdat dit geen jpeg bestanden zijn`);
    return;
  }
  console.log('handle files this: ', this);
  const sizes = await Promise.all(images.map(img=>getHeightAndWidthFromFile(img)));
  const uploadImagesBound: typeof uploadImages = uploadImages.bind(this);
  uploadImagesBound(images, sizes, this.props.onUploadComplete);
}

const getHeightAndWidthFromFile = (file: File): Promise<originalSize> => new Promise(resolve => {
  const img = new Image();
  img.onload = () => resolve({
      height: img.height,
      width: img.width
  });

  img.src = window.URL.createObjectURL(file);
})

export function onSWMessage (event: FilesSWEvent ) {
  console.log('swevent', event);
  alert(`${event.data.files.length} files were received`);
  handleFiles(event.data.files);
};

// from https://github.com/GoogleChromeLabs/squoosh/
// modified to use multiple files
export function getSharedImages(): Promise<FileList> {
  return new Promise((resolve) => {
    console.log('getSharedImages')
    const onmessage = (event: MessageEvent) => {
      console.log('event.data', event.data);
      if (event.data.action !== 'load-image') return;
      resolve(event.data.files);
      navigator.serviceWorker.removeEventListener('message', onmessage);
    };

    navigator.serviceWorker.addEventListener('message', onmessage);

    // This message is picked up by the service worker - it's how it knows we're ready to receive
    // the file.
    new BroadcastChannel('share-ready').postMessage('share-ready');
  });
}